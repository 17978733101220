import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import LoadingIconGray from "../components/Common/LoadingGray";
import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../Navbar/AdminNavbar";
import Dashboard from "../views/admin/Dashboard";

const Admin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [permissionList, setPermissionList] = useState([]);

  const fetchUerInfo = async (token) => {
    setLoading(true);
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let result = await res.json();
    if (result?.data?.length == 0) {
      toast(result?.message);
      localStorage.removeItem("token");
      navigate("/auth/login");
    } else {
      setUserInfo(result?.data?.user);
      setPermissionList(result?.data?.permissions);
    }
    setLoading(false);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token == null) navigate("/auth/login");
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <LoadingIconGray />
        </div>
      ) : (
        <div>
          <Sidebar sidebarPermissions={permissionList} />
          <div className="relative md:ml-[218px] bg-blueGray-100">
            <AdminNavbar userInfo={userInfo} />
            <div className="px-4 md:px-10 mx-auto w-full py-6">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Admin;
