export const CustomPagination = ({ setCurrentPage, current, total }) => {
  const handlePagination = (currentPage) => {
    if (currentPage > total || currentPage < 1) {
      return;
    }
    setCurrentPage(currentPage);
  };

  const skipCurrent = (type, howMany) => {
    if (
      (current === 1 && type === "pre") ||
      (current === total && type === "next")
    ) {
      return 0;
    } else if (type === "pre" && current - howMany >= 1) {
      setCurrentPage(current - howMany);
    } else if (type === "next" && current + howMany <= total) {
      setCurrentPage(current + howMany);
    }
  };

  const singlePageNumber = (index) => {
    return (
      <li
        onClick={() => handlePagination(index)}
        key={index}
        className={`cursor-pointer text-sm font-500 w-9 h-9 flex justify-center items-center rounded-lg ${
          current === index ? " text-[#7F56D9] bg-[#F9F5FF]" : "text-[#667085]"
        }`}
      >
        <p>{index}</p>
      </li>
    );
  };

  const skipPageNumber = (type, howMany) => {
    return (
      <li>
        <p
          onClick={() => skipCurrent(type, howMany)}
          className="cursor-pointer text-sm font-500 w-9 h-9 flex justify-center items-center"
        >
          ...
        </p>
      </li>
    );
  };

  const nextPreButtonRender = () => {
    return (
      <>
        <p
          className={`border-[1px] border-[#D0D5DD] rounded-[8px]  py-[6px] px-[14px] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] text-[#344054] text-sm font-medium ${
            current === 1
              ? "bg-[#e6e6e6aa] cursor-not-allowed"
              : "cursor-pointer"
          }`}
          onClick={() => handlePagination(current - 1)}
        >
          Previous
        </p>

        <p
          className={` border-[1px] border-[#D0D5DD] rounded-[8px]  py-[6px] px-[14px] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] text-[#344054] text-sm font-medium ${
            current === total
              ? "bg-[#e6e6e6aa] cursor-not-allowed"
              : "cursor-pointer"
          }`}
          onClick={() => handlePagination(current + 1)}
        >
          Next
        </p>
      </>
    );
  };

  return (
    <div className="flex items-center justify-between z-[999]">
      <ul className="flex gap-x-2 items-center">
        {total < 7 ? (
          <>
            {Array.apply(0, Array(total)).map((arr, i) =>
              singlePageNumber(i + 1)
            )}
          </>
        ) : current % 5 >= 0 && current > 4 && current + 2 < total ? (
          <>
            {singlePageNumber(1)}
            {skipPageNumber("pre", 2)}
            {singlePageNumber(current - 1)}
            {singlePageNumber(current)}
            {singlePageNumber(current + 1)}
            {skipPageNumber("next", 2)}
            {singlePageNumber(total)}
          </>
        ) : current % 5 >= 0 && current > 4 && current + 2 >= total ? (
          <>
            {singlePageNumber(1)}
            {skipPageNumber("pre", current - (total - 5))}
            {Array.from({ length: 5 }, (_, i) => i + total).map((arr, i) =>
              singlePageNumber(i + total - 4)
            )}
          </>
        ) : (
          <>
            {Array.apply(0, Array(5)).map((arr, i) => singlePageNumber(i + 1))}
            {skipPageNumber("next", 6 - current)}
            {singlePageNumber(total)}
          </>
        )}
      </ul>
      <div className="flex gap-x-3">{nextPreButtonRender()}</div>
    </div>
  );
};
