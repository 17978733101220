import { useId } from "react";
import ReactSelect from "react-select";

const CustomDropDown = (props) => {
  const { disabled, ...rest } = props; // Extract the disabled prop

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #CDD1D7",
      width: "100%",
      padding: "4px 7px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#0F223C",
    }),
  };

  return (
    <ReactSelect
      {...rest}
      styles={customStyles}
      isClearable
      isDisabled={disabled} // Pass the disabled state here
      instanceId={useId()}
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        outlineWidth: 10,
        colors: {
          ...theme.colors,
          primary25: "#dddbff",
          primary: "#2C4DFF",
        },
      })}
    />
  );
};

export default CustomDropDown;
