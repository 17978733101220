import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { LoadingButton, User } from "../../assets/svg/SVG";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import LoadingIconGray from "../Common/LoadingGray";

const ChangePassworod = ({
  isOpen,
  toggleDrawer,
  handleSubmit,
  password,
  setPassword,
  passwordLoading,
  newPassword,
  setNewPassword,
}) => {
  const [token, setToken] = useState();
  const [isEnabled, setIsEnabled] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [securityData, setCurityData] = useState(false);
  const [step, setStep] = useState(1);
  const [otpCode, setOtpCode] = useState("");
  const [isLoading, setIsLoding] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let twoFactor = JSON.parse(localStorage.getItem("isTwoFactor"));
    setIsEnabled(twoFactor);
    setToken(token);
  }, []);

  const toggleSwitch = async () => {
    setIsEnabled(!isEnabled);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    if (!isEnabled) {
      setIsOpen(true);
      setIsLoding(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/users/security`,
          requestOptions
        );
        const result = await response.json();
        if (result) {
          setCurityData(result.data);
          setIsLoding(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/users/security/disable`,
          requestOptions
        );
        const result = await response.json();
        toast(result?.message);
        localStorage.setItem("isTwoFactor", false);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
    },
  };

  const submitOpt = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/users/security/verify-otp/${otpCode}`,
        requestOptions
      );
      const result = await response.json();
      toast(result?.message);
      if (result?.message != "Invalid code!") {
        localStorage.setItem("isTwoFactor", true);
        setIsOpen(false);
        setStep(1);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="570px"
        className="overflow-x-scroll 2xl:overflow-x-hidden"
      >
        <div className="px-12 pt-12">
          <h4 className="flex items-center gap-x-[10px] text-lg font-bold">
            <User /> <span>Change Password</span>{" "}
          </h4>
          <hr className="mt-3 mb-3 border-[1px] bg-[#E0E0E0]" />

         

          <form onSubmit={(e) => handleSubmit(e)} className="text-[#2C3C57]">
            <label className="text-sm font-semibold flex items-center">
              Current Password
              <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              value={password}
              className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
              placeholder="Password"
            />
            <label className="text-sm font-semibold flex items-center">
              Update Password
              <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
            </label>
            <input
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              value={newPassword}
              className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
              placeholder="Password"
            />

            <div className="text-center mt-6">
              <button
                disabled={passwordLoading}
                className={` ${
                  passwordLoading && "cursor-not-allowed"
                } flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                type="submit"
              >
                {passwordLoading && <LoadingButton />}
                <span className="ml-5">Change Password</span>
              </button>
            </div>
          </form>
        </div>
      </Drawer>

      <Modal
        style={{
          ...customStyles,
          overlay: {
            zIndex: 9999,
          },
          content: {
            zIndex: 9999,
            ...customStyles.content,
          },
        }}
        isOpen={modalIsOpen}
      >
        {isLoading ? (
          <LoadingIconGray />
        ) : (
          <div>
            <div
              onClick={(e) => {
                if (step === 1) {
                  setIsOpen(false);
                  setIsEnabled((prev) => !prev);
                } else {
                  setIsOpen(false);
                }
              }}
              className="flex cursor-pointer justify-end"
            >
              <CloseIcon />
            </div>

            <div className="flex justify-center items-center py-4">
              {step == 1 && (
                <div>
                  <h3 className="font-medium py-2">
                    Scan the code with Google Authenticator app
                  </h3>
                  {securityData && (
                    <div className="flex justify-center items-center">
                      <img
                        src={securityData?.otp_secret}
                        className="w-[200px] h-[200px]"
                      />
                    </div>
                  )}

                  <div className=" flex justify-center mt-8">
                    <button
                      onClick={() => setStep(2)}
                      className={` w-[200px]  flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

              {step == 2 && (
                <div className="w-[400px] mx-auto">
                  <h3 className="text-2xl font-bold flex justify-center items-center">
                    Two-Factor Authentication
                  </h3>
                  <p className="text-center text-gray-500 py-3">
                    Enter Two-Factor authentication code to verify your identity
                  </p>
                  <div className="">
                    <input
                      onChange={(e) => setOtpCode(e.target.value)}
                      placeholder="Authentication Code"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                    />
                    <button
                      onClick={submitOpt}
                      className={` w-full mt-4 flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150`}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ChangePassworod;
