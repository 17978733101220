import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "../../assets/svg/SVG";
import Modal from "react-modal";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [server, setServer] = useState("fn");

  const handleUserLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    var requestOptions = {
      method: "POST",
      body: JSON.stringify({
        login: email,
        password: password,
      }),
    };

    let baseUrl;
    if (server == "gn") {
      baseUrl = process.env.REACT_APP_GN_BASE_URL;
    } else if(server == "cdemo") {
      baseUrl = process.env.REACT_APP_CTRADER_DEMO_BASE_URL;
    }else if(server == "clive") {
      baseUrl = process.env.REACT_APP_CTRADER_LIVE_BASE_URL;
    }else{
      baseUrl = process.env.REACT_APP_FN_BASE_URL;
    }

    try {
      let res = await fetch(`${baseUrl}/authorize`, requestOptions);
      const data = await res.json();

      if (res.status != 401) {
        setLoading(false);

        if (data?.token) {
          localStorage.setItem("token", data?.token);
          localStorage.setItem("email", email);
          localStorage.setItem("journalServer",server);
          navigate("/admin/dashboard");
        }
      } else {
        setLoading(false);
      }
      toast(data?.message);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <div
        className="mx-auto h-[100vh] w-full bg-[#1E293B]"
        style={{
          backgroundImage: `url("/register_bg_2.png")`,
        }}
      >
        <div className="w-full lg:w-4/12 px-4 h-full  justify-center flex items-center mx-auto ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 bg-[#E2E8F0]">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="py-10">
                <h3 className="text-3xl text-center font-bold">
                  Trading Journal{" "}
                </h3>
              </div>
              <form onSubmit={(e) => handleUserLogin(e)}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="server"
                  >
                    Server
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    required
                    onChange={(e) => setServer(e.target.value)}
                  >
                    <option value="fn">FundedNext-Server</option>
                    <option value="gn">GrowthNext-server</option>
                    <option value="cdemo">cTrader-Demo</option>
                    <option value="clive">cTrader-Live</option>
                  </select>
                </div>

                <div className="text-center mt-6">
                  <button
                    disabled={loading}
                    className={` ${
                      loading && "cursor-not-allowed"
                    } flex items-center justify-center bg-[#1E293B] text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                    type="submit"
                  >
                    {loading && <LoadingButton />}

                    <span className="ml-5">Sign In</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
